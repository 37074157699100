import { useState, useEffect } from 'react';

export const useTheme = () => {
  const [theme, setTheme] = useState(localStorage.theme);
  const colorTheme = theme === 'dark' ? 'light' : 'dark';

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(theme);
    localStorage.setItem('theme', theme);
    const themeChangedEvent = new CustomEvent('theme-changed', {
      detail: { colorTheme },
    });
    window.dispatchEvent(themeChangedEvent);
  }, [theme, colorTheme]);

  return { colorTheme, setTheme };
};
